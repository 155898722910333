<template>
	<label :for="sman + refs">
		<input
			:id="sman + refs"
			v-model.trim="value"
			@blur="eventBlur"
			@focus="eventFocus"
			:value="sman"
			:name="refs"
			type="radio"
			:placeholder="item.placeholder"
		/>
	</label>
</template>

<script>
import DataForInput from '@/store/repeatLoan/longFormOptions';
import { mapState } from 'vuex';
import tracing from '@/common/tracing';

export default {
	inheritAttrs: false,

	name: 'formRadio',

	data: function () {
		return {
			item: DataForInput[this.refs]
		};
	},

	props: {
		sman: {
			type: String,
			default: ''
		},
		refs: {
			type: String,
			default: 'null'
		}
	},

	computed: {
		value: {
			get() {
				return this.valueVuex[this.refs];
			},
			set(value) {
				this.$store.dispatch('repeatLoan/saveSendData', {
					target: this.refs,
					value: value
				});
			}
		},
		...mapState({
			valueVuex: (state) => state.repeatLoan.sendData
		})
	},

	methods: {
		eventBlur: function () {
			this.$store.dispatch('repeatLoan/errorShow', {
				target: this.refs,
				value: true
			});
		},
		eventFocus: function () {
			this.$store.dispatch('repeatLoan/errorShow', {
				target: this.refs,
				value: false
			});
			tracing.traceInput({
				action: 164,
				refs: this.refs,
				value: this.valueVuex[this.refs]
			});
		}
	}
};
</script>

<style scoped>
input[type=radio] {
  border: 1px solid rgba(67, 172, 52, 0.3);
  appearance: none;
  margin-left: 6.93vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 5.60vw;
  width: 11.73vw;
  height: 11.73vw;
  border-radius: 2.13vw;
}

input[value=M]::before {
  content: 'М';
}

input[value=F]::before {
  content: 'Ж';
}

input[type=radio]:checked {
  border: 3px solid rgba(67, 172, 52, 0.3);
}

@media (min-width: 760px) {
  input[type=radio] {
    width: 3.63vw;
    height: 3.23vw;
    border-radius: 10px;
    font-size: 1.13vw;
    margin-left: 1.21vw
  }
}

@media (min-width: 1240px) {
  input[type=radio] {
    width: 45px;
    height: 40px;
    border-radius: 10px;
    font-size: 14px;
    margin-left: 15px
  }
}
</style>
